<div class="flex flex-col md:flex-row md:justify-between">
  <div>
    <h1 class="text-3xl font-medium" i18n>Users</h1>
  </div>
</div>

<div class="mb-2 mt-3">
  <app-breadcrumbs
    [segments]="[
      { title: 'Users', url: 'admin/users' },
      { title: user ? user.firstName + ' ' + user.lastName : 'Loading...'},
    ]"
  />
</div>

<div
  *ngIf="(isOrganisationPending$ | async) === false && (isUserPending$ | async) === false; else showLoadingSpinner"
  class="mt-12 flex w-full flex-col lg:flex-row lg:justify-evenly"
>
  <ng-container *ngIf="user && organisation; else showLoadingSpinner">
    <!-- User Details -->
    <div class="mb-12 flex w-full flex-row lg:mb-0 lg:basis-2/5">
      <div class="w-full max-w-96">
        <!-- First Name -->
        <div>
          <h2 i18n class="text-sm font-light text-gray-600">First name</h2>
          <p class="mb-5 mt-1 text-xl text-black">{{ user.firstName }}</p>
        </div>

        <!-- Last Name -->
        <div>
          <h2 i18n class="text-sm font-light text-gray-600">Last name</h2>
          <p class="mb-5 mt-1 text-xl text-black">{{ user.lastName }}</p>
        </div>

        <!-- Organisation Name -->
        <div>
          <h2 i18n class="text-sm font-light text-gray-600">
            Organisation name
          </h2>
          <p class="mb-5 mt-1 text-xl text-black">
            {{ organisation.name || 'N/A' }}
          </p>
        </div>

        <!-- Phone Number -->
        <div>
          <h2 i18n class="text-sm font-light text-gray-600">Phone number</h2>
          <p class="mb-5 mt-1 text-xl text-black">
            {{ user.phoneNumber || '&nbsp;' }}
          </p>
        </div>

        <!-- Email Address -->
        <div>
          <h2 i18n class="text-sm font-light text-gray-600">Email address</h2>
          <p class="mb-5 mt-1 text-xl text-black">
            {{ user.email || '&nbsp;' }}
          </p>
        </div>

        <!-- Date Created -->
        <div>
          <h2 i18n class="text-sm font-light text-gray-600">Date created</h2>
          <p class="mb-5 mt-1 text-xl text-black">
            {{ user.createdAt | date: 'dd/MM/yyyy' || '&nbsp;' }}
          </p>
        </div>

        <!-- Created By -->
        <div *ngIf="user?.createdBy">
          <h2 i18n class="text-sm font-light text-gray-600">Created by</h2>
          <p class="mb-5 mt-1 text-xl text-black">
            {{ (user.createdBy?.firstName?.length &&
            user.createdBy?.lastName?.length) ? (user.createdBy!.firstName + ' '
            + user.createdBy!.lastName) : 'N/A' }}
          </p>
        </div>

        <!-- Status -->
        <div>
          <h2 i18n class="text-sm font-light text-gray-600">Status</h2>
          <p class="mb-5 mt-1 text-xl text-black">
            {{ (user.status | titlecase) || 'DEACTIVATED' }}
          </p>
        </div>
      </div>
    </div>

    <app-divider class="mx-10 hidden lg:block lg:basis-1/5" />

    <!-- User Actions -->
    <div class="flex justify-center lg:basis-2/5 lg:flex-row lg:justify-start">
      <ul
        class="w-full *:border-b *:border-alpha-gray-20 *:py-4 first:*:border-t lg:max-w-96 lg:justify-center"
      >
        <!-- Force password reset -->
        <li>
          <button
            (click)="showModal = 'admin-reset-password'"
            class="flex w-full items-center justify-between font-medium"
          >
            Force password reset
            <div
              class="ml-12 flex h-10 w-10 items-center justify-center rounded-full bg-alpha-blue-10 p-2"
            >
              <div class="h-4">
                <app-icon icon="chevron-forward" />
              </div>
            </div>
          </button>
        </li>

        <!-- View users jobs -->
        <li *ngIf="organisation && !isUserInvitePending">
          <button
            (click)="viewUserJobs()"
            class="flex w-full items-center justify-between font-medium"
          >
            View user jobs
            <div
              class="ml-12 flex h-10 w-10 items-center justify-center rounded-full bg-alpha-blue-10 p-2"
            >
              <div class="h-4">
                <app-icon icon="chevron-forward" />
              </div>
            </div>
          </button>
        </li>

        <!-- Remove user -->
        <li>
          <button
            (click)="showModal = 'disable-user'"
            class="flex w-full items-center justify-between font-medium"
          >
            Remove user

            <div
              class="ml-12 flex h-10 w-10 items-center justify-center rounded-full bg-alpha-blue-10 p-2"
            >
              <div class="h-4">
                <app-icon icon="chevron-forward" />
              </div>
            </div>
          </button>
        </li>
      </ul>
    </div>

    <app-admin-reset-password-modal
      [show]="showModal === 'admin-reset-password'"
      (closed)="showModal = ''"
      userId="{{ user.id }}"
      userName="{{ user.firstName }} {{ user.lastName }}"
    />

    <app-admin-remove-user-modal
      [show]="showModal === 'disable-user'"
      (cancelled)="showModal = ''"
      (closed)="closedAdminRemoveUserModal()"
      userId="{{ user.id }}"
      userName="{{ user.firstName }} {{ user.lastName }}"
      organisationId="{{ organisation.id }}"
      organisationName="{{ organisation.name }}"
    />
  </ng-container>
</div>

<ng-template #showLoadingSpinner>
  <div class="mt-20 flex items-center justify-center">
    <app-loading />
  </div>
</ng-template>
